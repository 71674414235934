import React from "react"
import Icon from "./icon"
import Heading from "./heading"
import Kicker from "./kicker"
import Content from "./content"
import UniversalLink from "./universalLink"

const IconBox = ({
  data,
  principle,
  headingLevel,
  heading,
  kicker: defaultKicker,
}) => {
  const { kicker, title, icon, content, iconColor } = data

  return (
    <div className="space-y-8">
      <Icon
        id={icon.title}
        className={`mb-0 ${(iconColor || principle) && "icon icon--inverted"}`}
      />
      {(defaultKicker || kicker) && <Kicker>{defaultKicker || kicker}</Kicker>}
      {title && (
        <Heading level={headingLevel ? headingLevel : "3"}>{title}</Heading>
      )}

      {"Telefone" !== title && content && <Content data={content} />}

      {/*  */}

      {/* {"Telefone" === title && content && <Content data={content} />} */}

      {"Telefone" === title && content && (
        <p>
          <a
            onClick={() => {
              dataLayer.push({
                event: "click-phone-number",
              })
            }}
            href="tel:+555130623090"
          >
            (51) 3062-3090
          </a>
        </p>
      )}

      {"Endereço" === title && (
        <div className="font-bold text-white hover:text-accent ">
          <UniversalLink
            title={title}
            className="flex items-center group"
            to={`http://google.com/maps/place/${`Av. Ceará, 1093 São João, Porto Alegre, RS CEP: 90240-511`}`}
          >
            <span>Como chegar</span>
            <Icon
              id="long-arrow-alt-right"
              className="icon icon--white mb-0 w-3 ml-4"
            />
          </UniversalLink>
        </div>
      )}
    </div>
  )
}

export default IconBox
